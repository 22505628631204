export const HamburgerIcon = () => {
    return (
        <svg
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-black dark:stroke-white"
        >
            <path
                d="M1 1H17"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M1 7H17"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M1 13H17"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};
