import type { HTMLAttributes } from "react";

interface MonitorIconProps {
    className?: HTMLAttributes<SVGElement>["className"];
}

export const MonitorIcon = ({ className }: MonitorIconProps) => {
    return (
        <svg fill="none" className={`w-6 h-6 ${className}`} viewBox="0 0 24 24">
            <path d="M4 6a2 2 0 012-2h12a2 2 0 012 2v7a2 2 0 01-2 2H6a2 2 0 01-2-2V6z"></path>
            <path d="M14 15c0 3 2 5 2 5H8s2-2 2-5"></path>
        </svg>
    );
};
