import { modalAfterBottomModalContainerId, modalPortalContainerId } from "client/config/divIds";
import { useKeyboard } from "client/hooks/utils/useKeyboard";
import { useMounted } from "client/hooks/utils/useMounted";
import { MouseEvent, useEffect } from "react";

export const useModal = (
    isOpen: boolean,
    closeOnOverlayClick: boolean,
    backgroundScroll: boolean,
    close?: () => void,
    isOverBottomModal?: boolean,
) => {
    const { mounted } = useMounted();
    useKeyboard("Escape", isOpen, () => close && close());

    const handleClick = (_event: MouseEvent<HTMLDivElement>) => {
        _event.stopPropagation();
        closeOnOverlayClick && close && close();
    };

    const element =
        typeof window === "undefined"
            ? null
            : document.getElementById(isOverBottomModal ? modalAfterBottomModalContainerId : modalPortalContainerId);

    useEffect(() => {
        if (!backgroundScroll && isOpen) {
            document.body.style.overflowY = "hidden";
        }
        if (!isOpen) {
            document.body.style.overflowY = "scroll";
        }
    }, [isOpen]);

    return {
        mounted,
        handleClick,
        element,
    };
};
