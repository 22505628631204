import { useLanguage } from "client/contexts/LanguageProvider";
import { translateFunc } from "client/utils/translateFunc";
import { Modal } from "components/common/Modal/Modal";
export interface RedirectConfirmationModalProps {
    link: string;
    isOpen: boolean;
    close: () => void;
}

export const RedirectConfirmationModal = ({ link, isOpen, close }: RedirectConfirmationModalProps) => {
    const { i18n } = useLanguage();
    const handleRedirect = () => {
        window.location.href = i18n ? (i18n.language === "en" ? link : `/${i18n.language}/${link}`) : link;
    };

    const handleClose = () => {
        close();
    };

    return (
        <Modal isOpen={isOpen} close={handleClose}>
            <div className="w-full h-screen flex justify-center items-center z-50" onClick={(e) => {}}>
                <div
                    className="inline-flex flex-col lg:mx-auto bg-white dark:bg-darkSurface-200 px-8 py-8 rounded-xl mx-4 text-black dark:text-white"
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="text-xl font-semibold mb-8 capitalize">{translateFunc("common:leave-editor")}</div>
                    <div className="flex justify-end items-center gap-4 font-semibold">
                        <div
                            className="cursor-pointer px-4 py-2 bg-irGray-400 dark:bg-darkSurface-300 dark:hover:bg-darkSurface-400 hover:bg-irGray-600 rounded-xl capitalize"
                            onClick={handleClose}
                        >
                            {translateFunc("common:cancel")}
                        </div>
                        <div
                            className="cursor-pointer px-4 py-2 bg-irRed-400 hover:bg-red-700 text-white rounded-xl capitalize"
                            onClick={handleRedirect}
                        >
                            {`${translateFunc("common:yes")}, ${translateFunc("common:leave")}`}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
