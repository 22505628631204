import { ReactNode } from "react";
import { ArrowIcon } from "components/common/Icons/ArrowIcon";
import { NavLinkParent } from "../../NavLinkParent/NavLinkParent";
import { useDesktopNavLink } from "./useDesktopNavLink";
import translatedRoutedSlugs from "translatedRouteSlugs";
import { useRouter } from "next/router";

export interface DesktopNavLinkProps {
    title: string | ReactNode;
    link: string;
    isEditor: boolean;
    outlink?: boolean;
    isChild?: boolean;
    subItems?: NavChild[];
    urlMatcher?: (url: string) => boolean;
}

interface NavChild {
    title: string | ReactNode;
    link: string;
    outlink?: boolean;
    urlMatcher?: (url: string) => boolean;
}

export const DesktopNavLink = ({
    link,
    title,
    isEditor,
    outlink,
    isChild = false,
    subItems,
    urlMatcher,
}: DesktopNavLinkProps) => {
    const { hasChildren, isFocused, setIsFocused, isActive, ref } = useDesktopNavLink(
        subItems,
        isChild,
        link,
        urlMatcher,
    );
    const { locale } = useRouter();
    link = link === "/" ? "/" : "/" + (translatedRoutedSlugs as any)[link]?.[locale ?? "en"] ?? link;

    return (
        <li
            onClick={() => {
                hasChildren && setIsFocused(!isFocused);
            }}
            onMouseEnter={() => {
                hasChildren && setIsFocused(true);
            }}
            onMouseLeave={() => {
                hasChildren && setIsFocused(false);
            }}
            className="relative w-full"
            ref={ref}
            style={{
                fontSize: "15px",
            }}
        >
            <NavLinkParent
                link={link}
                isParent={!!hasChildren}
                isChild={!!isChild}
                twClasses={"light:hover:bg-slate-200 dark:hover:bg-darkSurface-200"}
                outlink={outlink}
                isEditor={isEditor}
            >
                <div className={`whitespace-nowrap ${isActive && `font-semibold`}`}>{title}</div>
                {subItems &&
                    subItems.length &&
                    (isFocused ? (
                        <div className="ml-2 rotate-180">
                            <ArrowIcon />
                        </div>
                    ) : (
                        <div className="ml-2">
                            <ArrowIcon />
                        </div>
                    ))}
            </NavLinkParent>

            {hasChildren && isFocused && subItems && (
                <ul className="shadow-2xl absolute top-full right-0 left-auto bottom-auto flex flex-col justify-start gap-2 bg-white dark:bg-darkSurface-200 rounded-md overflow-hidden z-30">
                    {subItems.map((item, index) => {
                        return (
                            <DesktopNavLink
                                key={index}
                                link={item.link}
                                title={item.title}
                                isEditor={isEditor}
                                outlink={item.outlink}
                                isChild={true}
                                urlMatcher={item.urlMatcher}
                            />
                        );
                    })}
                </ul>
            )}
        </li>
    );
};
