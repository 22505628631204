import { useLanguage } from "client/contexts/LanguageProvider";
import { useKeyboard } from "client/hooks/utils/useKeyboard";
import { useRouter } from "next/router";
import { useState, useRef, useEffect } from "react";
import translatedRoutedSlugs from "translatedRouteSlugs";
import { DesktopNavLinkProps } from "./DesktopNavLink";

export const useDesktopNavLink = (
    subItems: DesktopNavLinkProps["subItems"],
    isChild: DesktopNavLinkProps["isChild"],
    link: DesktopNavLinkProps["link"],
    urlMatcher: DesktopNavLinkProps["urlMatcher"],
) => {
    const [isFocused, setIsFocused] = useState(false);
    const router = useRouter();

    const hasChildren = subItems && subItems.length > 0;

    const ref = useRef<HTMLLIElement | null>(null);
    const handleOutSideClick = (event: MouseEvent) => {
        event.stopPropagation();
        if (!ref.current || ref.current.contains(event.target as Node)) {
            return;
        }

        setIsFocused(false);
    };

    useEffect(() => {
        if (!ref.current || !isFocused) return;
        document.addEventListener("click", handleOutSideClick);
    }, [ref, isFocused]);

    useKeyboard("Escape", isFocused, () => setIsFocused(false));

    const { i18n } = useLanguage();
    link = i18n ? (i18n.language === "en" ? link : `/${i18n.language}${link}`) : link;
    link = link[link.length - 1] === "/" && link.length > 1 ? link.substring(0, link.length - 1) : link;

    const getIsActive = (): boolean => {
        const pathname = `${router.pathname}`;
        if (link === `/${router.locale}` && subItems && pathname === "/") {
            return false;
        }
        if (router.locale && link !== "/") {
            link = link.replace(`/${router.locale}`, "");
        }
        if (link === "") link = "/";
        if (router.locale && router.locale !== "en" && link !== "/") {
            link = `/${(translatedRoutedSlugs as any)[link]?.["en"]}`;
        }
        if (subItems) {
            return subItems.some((linkItem) => {
                let link = linkItem.link;
                if (router.locale && link !== "/") {
                    link = link.replace(`/${router.locale}`, "");
                }
                if (link === "") link = "/";
                if (router.locale && router.locale !== "en" && link !== "/") {
                    link = `/${(translatedRoutedSlugs as any)[link]?.["en"]}`;
                }
                return (
                    pathname === link || (typeof linkItem.urlMatcher !== "undefined" && linkItem.urlMatcher(pathname))
                );
            });
        }

        return pathname === link || (typeof urlMatcher !== "undefined" && urlMatcher(pathname));
    };

    return {
        isFocused,
        setIsFocused,
        hasChildren,
        isActive: getIsActive(),
        ref,
    };
};
