import { useToggle } from "client/hooks/utils/useToggle";
import { Translate } from "components/common/Translate/Translate";
import { Internationalization } from "components/modules/Internationalization/Internationalization";
import type { LanguageProps } from "components/modules/Layout/Footer/Footer";

export interface MobileLanguageProps extends LanguageProps {}

export const MobileLanguage = ({ url, urlSlugVariables }: MobileLanguageProps) => {
    const useToggleReturn = useToggle();
    return (
        <li
            className="flex items-center gap-4 w-full px-6 py-4 border-t border-t-slate-200 dark:border-t-darkSurface-400"
            onClick={useToggleReturn.open}
        >
            <span className="capitalize text-black dark:text-white">
                <Translate keyName="common:change-language" />
            </span>
            <Internationalization
                url={url}
                urlSlugVariables={urlSlugVariables}
                onlyIconMode={true}
                useToggleReturn={useToggleReturn}
            />
        </li>
    );
};
