import { ReactNode } from "react";
import { createPortal } from "react-dom";
import { useModal } from "./useModal";

interface ModalProps {
    isOpen: boolean;
    close?: () => void;
    backgroundScroll?: boolean;
    closeOnOverlayClick?: boolean;
    overlayBackground?: string;
    isOverBottomModal?: boolean;
    /**
        add pointer-events: auto; to the first children of the modal when 
        interactiveBackground is true
    */
    interactiveBackground?: boolean;
    children: ReactNode;
}

export const Modal = ({
    isOpen,
    children,
    close,
    overlayBackground,
    isOverBottomModal,
    closeOnOverlayClick = true,
    backgroundScroll = false,
    interactiveBackground = false,
}: ModalProps) => {
    const { mounted, handleClick, element } = useModal(
        isOpen,
        closeOnOverlayClick,
        backgroundScroll,
        close,
        isOverBottomModal,
    );

    if (!isOpen || !mounted || !element) return null;

    return createPortal(
        <div
            className={`fixed z-40 inset-0 ${overlayBackground || "bg-slate-900/50 dark:bg-black/50"} overflow-y-auto ${
                interactiveBackground && "pointer-events-none"
            }`}
            onClick={handleClick}
        >
            <div
                className={`h-full flex items-center justify-center touch-auto ${
                    interactiveBackground && "pointer-events-none"
                }`}
            >
                {children}
            </div>
        </div>,
        element,
    );
};
