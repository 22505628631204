import { DesktopNavLink } from "./DesktopNavLink/DesktopNavLink";
import { navLinks } from "components/modules/Navigation/navLinks";

interface DesktopNavigationProps {
    isEditor: boolean;
}

export const DesktopNavigation = ({ isEditor }: DesktopNavigationProps) => {
    return (
        <ul className="hidden lg:flex items-center ml-auto">
            {navLinks.map((e, index) => {
                return <DesktopNavLink key={index} {...e} isEditor={isEditor} />;
            })}
        </ul>
    );
};
